import {
  api,
  createCrudService,
  responseToOptions,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService(
  '/api/controltower/takeUpCancellations',
  api,
  { importLogUrl: '/api/controltower/importLog' }
)

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  Object.keys(ctx.farmOptionsByProducer).forEach((x) => {
    ctx.farmOptionsByProducer[x] = responseToOptions(
      ctx.farmOptionsByProducer[x]
    )
  })

  fieldsById.purchaseContract.options = ctx.purchaseContractOptions
  fieldsById.producerName.disabled = true
  fieldsById.producerName.onValueChange = (value, fields) => {
    fields.producerName.disabled = true
  }

  fieldsById.purchaseContract.onValueChange = (changedValue, fields) => {
    const purchaseContractId = changedValue?.value
    const farmId = fields.farm.value?.value
    const producerId = ctx.producerIdByPurchaseContractId[purchaseContractId]
    fields.producerName.value = ctx.producerNameByProducerId[producerId]
    const farmOptions = ctx.farmOptionsByProducer[producerId] ?? []

    if (!farmOptions.some((x) => x.value == farmId)) {
      fields.farm.value = null
    }
  }

  fieldsById.farm.options = async ({ itemData }) => {
    const purchaseContractId = itemData.purchaseContract?.value
    const producerId = ctx.producerIdByPurchaseContractId[purchaseContractId]
    return ctx.farmOptionsByProducer[producerId] ?? []
  }
}

export default service
