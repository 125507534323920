<template>
  <div v-if="metadata != null">
    <custom-cg-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="controlTower.pages"
      :validation-schema="createValidationSchema"
    />
  </div>
</template>

<script>
import { CustomCgCrudMetaView, helpers } from '@cargill/shared'
import service from '../api/takeUpCancellationsService'
import { generateFieldsSchema } from '@brain/core'
import * as yup from 'yup'

export default {
  components: { CustomCgCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      createValidationSchema: Function
    }
  },
  methods: {
    async getMeta() {
      const meta = await service.getMetaWithValidation()
      this.createValidationSchema = (meta, translate) => {
        const validationSchema = generateFieldsSchema(meta, translate)
        validationSchema.saiCode = validationSchema.saiCode.transform((value) =>
          value ? value : null
        )
        const requiredMappings = {
          farm: ['lotReference', 'saiCode'],
          lotReference: ['saiCode'],
          quantity: ['saiCode']
        }

        helpers.includeImpactedRequired(
          requiredMappings,
          validationSchema,
          translate
        )
        return yup.object().shape(validationSchema)
      }
      return meta
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
